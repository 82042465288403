import React from "react";
import styled from "styled-components";
import { withPage } from "../../Page";
import Banner from "../../Components/Banner";
import PromotionSection from "../../Components/PromotionSection";
import ServiceSection from "../../Components/ServiceSection";
import NewsBlogSection from "../../Components/NewsBlogSection";
import ReservationSection from "../../Components/ReservationSection";

class HomePage extends React.Component {
  render() {
    return (
      <Wrapper>
        <Banner image="/images/banner01.png" extraCss="padding-top: 100px;" />
        <Container>
          <PromotionSection />
          <ServiceSection />
          <NewsBlogSection />
          <ReservationSection showSectionTitle={true} />
        </Container>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div``;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1181px;
  width: 100%;
`;

export default withPage(HomePage);
