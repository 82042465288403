import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import * as Widget2 from "./Widget2";

class ReservationSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: "",
        phone: "",
        expected_date: "",
        number_of_births: "",
        visited_date: "",
        visited_time: "",
        note: ""
      },
      showErrorHint: false,
      showSpinner: false,
      showSuccessMsg: false
    };
  }

  render() {
    let { showSectionTitle, textCenter } = this.props;
    let { data, showErrorHint, showSpinner, showSuccessMsg } = this.state;

    return (
      <Wrapper textCenter={textCenter}>
        {showSectionTitle && (
          <Widget2.SectionTitle css="padding: 35px 0px;">
            <div className="text">預約參觀</div>
            <div className="line" />
          </Widget2.SectionTitle>
        )}

        <p>
          {
            '開放時間11:00am~17:00pm\n請多利用線上預約，並填表後按"送出"\n參觀日期及時間將由櫃檯管家透過電話與您確認。'
          }
        </p>

        <div className="contact">
          {showSuccessMsg ? (
            <div className="text">{`申請預約參觀\n月子中心成功，客服將在近日與您聯繫!`}</div>
          ) : (
            <>
              <p>＊每欄皆為必填</p>
              <div className="container">
                <Widget2.Col
                  css={`
                    margin-right: 20px;
                    max-width: 300px;
                    width: 100%;
                    @media screen and (max-width: 600px) {
                      max-width: 230px;
                    }
                  `}
                >
                  <input
                    placeholder="姓名"
                    value={data.name}
                    onChange={e =>
                      this.setState({ data: { ...data, name: e.target.value } })
                    }
                  />
                  <input
                    placeholder="電話 0000-000-000"
                    value={data.phone}
                    onChange={e =>
                      this.setState({
                        data: { ...data, phone: e.target.value }
                      })
                    }
                  />
                  <input
                    placeholder="預產期 2019-01-01"
                    value={data.expected_date}
                    onChange={e =>
                      this.setState({
                        data: { ...data, expected_date: e.target.value }
                      })
                    }
                  />
                  <input
                    placeholder="胎次 1"
                    value={data.number_of_births}
                    onChange={e =>
                      this.setState({
                        data: { ...data, number_of_births: e.target.value }
                      })
                    }
                  />
                </Widget2.Col>
                <Widget2.Col
                  css={`
                    margin-right: 20px;
                    max-width: 300px;
                    width: 100%;
                    @media screen and (max-width: 600px) {
                      max-width: 230px;
                    }
                  `}
                >
                  <input
                    placeholder="欲參觀日期 2019-01-01"
                    value={data.visited_date}
                    onChange={e =>
                      this.setState({
                        data: { ...data, visited_date: e.target.value }
                      })
                    }
                  />
                  <input
                    placeholder="欲參觀時間 11:00 am or 17:00pm"
                    value={data.visited_time}
                    onChange={e =>
                      this.setState({
                        data: { ...data, visited_time: e.target.value }
                      })
                    }
                  />
                </Widget2.Col>
                <textarea
                  placeholder="請填入您的留言，我們將會有專人為您服務。"
                  value={data.note}
                  onChange={e =>
                    this.setState({ data: { ...data, note: e.target.value } })
                  }
                />
              </div>

              {showErrorHint && <div className="hint">{showErrorHint}</div>}

              {showSpinner ? (
                <Widget2.Center>
                  <Widget2.Spinner />
                </Widget2.Center>
              ) : (
                <button onClick={this._submit}>送出</button>
              )}
            </>
          )}
        </div>
      </Wrapper>
    );
  }

  _submit = () => {
    let { appActions } = this.props;
    let { data } = this.state;

    if (data.name === "" || data.phone === "") {
      return this.setState({ showErrorHint: "姓名與電話為必填喔！" });
    }

    if (
      data.expected_date === "" ||
      data.visited_date === "" ||
      data.visited_time === ""
    ) {
      return this.setState({
        showErrorHint: "請填寫媽媽預產期、預約日期及時間！"
      });
    }

    this.setState({ showSpinner: true });
    appActions
      .createVisitReservation(data)
      .then(() => this.setState({ showSuccessMsg: true }))
      .then(() => this.setState({ showSpinner: false }))
      .catch(err => {
        console.warn(err);
        this.setState({ showSpinner: false });
      });
  };
}

const Wrapper = styled.div`
  margin-bottom: 85px;
  padding: 20px;
  & > p {
    padding: 20px 0px 60px 0px;
    white-space: pre-wrap;
    letter-spacing: 1.4px;
    font-size: 14px;
    color: #101d1f;
    text-align: ${props => (props.textCenter ? "center" : "left")};
  }

  & > .contact {
    display: flex;
    flex-direction: column;
    justify-content: center;

    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
    border-radius: 19px;
    padding: 27px;
    width: 100%;

    & > .text {
      white-space: pre-wrap;
      font-weight: bold;
      font-size: 20px;
      color: #71c6d0;
      letter-spacing: 4px;
      text-align: center;
    }

    & p {
      margin-bottom: 27px;
      letter-spacing: 1.4px;
      font-weight: bold;
      font-size: 14px;
      color: #71c6d0;
    }

    & > .container {
      display: flex;
      justify-content: center;
      margin-bottom: 40px;

      & input {
        margin-bottom: 33px;
        border: 0px;
        border-radius: 22.5px;
        padding: 15px;
        width: 100%;
        max-height: 45px;
        height: 100%;
        background: #e5e5e5;

        :focus {
          outline: none;
        }

        ::placeholder {
          font-size: 12px;
          color: rgba(16, 29, 31, 0.3);
        }
      }

      & textarea {
        border: 1px solid #c2c2c2;
        border-radius: 19px;
        padding: 15px;
        max-width: 380px;
        max-height: 297px;
        min-height: 250px;
        width: 100%;
        height: 100%;
        resize: none;

        :focus {
          outline: none;
        }

        ::placeholder {
          font-size: 12px;
        }
      }

      @media screen and (max-width: 600px) {
        flex-wrap: wrap;
      }
    }

    & .hint {
      padding-bottom: 20px;
      width: 100%;
      text-align: center;
      font-size: 12px;
      color: red;
    }

    & button {
      margin: 0 auto 38px;
      border: 0px;
      padding: 10px;
      min-width: 170px;
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
      border-radius: 28px;
      background: white;
      font-size: 24px;
      color: #71c6d0;
      letter-spacing: 2.4px;
      text-align: center;
      cursor: pointer;

      :focus {
        outline: none;
      }

      @media screen and (max-width: 600px) {
        font-size: 15px;
      }
    }

    @media screen and (max-width: 414px) {
      padding: 20px;
    }
  }
`;

export default connect(
  null,
  ActionCreator
)(ReservationSection);
