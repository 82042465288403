import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import * as Widget2 from "./Widget2";

const ServiceItems = [
  { title: "專業醫師領軍", path: "/images/photo01.png", url: "/medical-team" },
  { title: "舒適高級環境", path: "/images/photo02.png", url: "/environment" },
  { title: "營養美味料理", path: "/images/photo03.png", url: "/cuisine" },
  { title: "青田電商", path: "/images/photo04.png", url: "/" }
];

class ServiceSection extends Component {
  render() {
    let { navActions } = this.props;

    return (
      <Wrapper>
        <Widget2.SectionTitle>
          <div className="text">優質服務，就在青田悅</div>
          <div className="line" />
        </Widget2.SectionTitle>

        <Container>
          {ServiceItems.map((item, i) => (
            <Button
              key={"service-item" + i}
              onClick={() => navActions.push(`${item.url}`)}
            >
              <img src={item.path} />
              <div className="text">{item.title}</div>
            </Button>
          ))}
        </Container>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 20px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 414px) {
    justify-content: center;
    flex-wrap: wrap;
  }
`;

const Button = styled.button`
  max-width: 281px;
  background: white;
  border: 0px;
  cursor: pointer;

  & > img {
    width: 100%;
    height: 100%;

    @media screen and (max-width: 414px) {
      max-width: 180px;
    }

    @media screen and (max-width: 375px) {
      max-width: 160px;
    }

    @media screen and (max-width: 320px) {
      max-width: 140px;
    }
  }

  & > .text {
    margin-bottom: 10px;
    font-size: 24px;
    color: rgba(16, 29, 31, 0.6);
    letter-spacing: 2.4px;
    text-align: center;

    @media screen and (max-width: 600px) {
      font-size: 15px;
    }
  }

  :focus {
    outline: none;
  }
`;

export default connect(
  null,
  ActionCreator
)(ServiceSection);
