import React, { Component } from "react";
import styled from "styled-components";

class PromotionSection extends Component {
  render() {
    return (
      <Wrapper>
        <Text>{"青田悅，\n讓媽媽寶寶安心的家。"}</Text>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;
`;

const Text = styled.p`
  padding: 46px 20px;
  text-align: center;
  white-space: pre-wrap;
  letter-spacing: 4px;
  font-weight: bold;
  font-size: 40px;
  color: #71c6d0;

  @media screen and (max-width: 600px) {
    padding: 20px;
    font-size: 20px;
  }
`;

export default PromotionSection;
