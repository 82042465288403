import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import ArticleListItem from "./ArticleListItem";
import * as Widget2 from "./Widget2";

class NewsBlogSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsItems: null,
      blogItems: null,
      showSpinner: true
    };
  }

  componentDidMount() {
    this._fetchRecords();
  }

  render() {
    let { navActions } = this.props;
    let { newsItems, blogItems, showSpinner } = this.state;

    if (showSpinner) {
      return (
        <Widget2.Center>
          <Widget2.Spinner />
        </Widget2.Center>
      );
    }

    return (
      <Wrapper>
        <Container>
          <Content hasMarginRight>
            <Widget2.SectionTitle>
              <div className="text">最新消息</div>
              <div className="line" />
            </Widget2.SectionTitle>

            {this._fetchLatestRecords(newsItems.results).map((item, i) => (
              <ArticleListItem
                key={"news-item" + i}
                from={"news"}
                data={item}
                css="margin-bottom: 35px;"
              />
            ))}
            <div className="button-container">
              <button onClick={() => navActions.push("/news")}>看更多 ></button>
            </div>
          </Content>

          <Content>
            <Widget2.SectionTitle>
              <div className="text">青田悅誌</div>
              <div className="line" />
            </Widget2.SectionTitle>

            {this._fetchLatestRecords(blogItems.results).map((item, i) => (
              <ArticleListItem
                key={"blog-item" + i}
                from={"blog"}
                data={item}
                css="margin-bottom: 35px;"
              />
            ))}
            <div className="button-container">
              <button onClick={() => navActions.push("/blog")}>看更多 ></button>
            </div>
          </Content>
        </Container>
      </Wrapper>
    );
  }

  _fetchRecords = () => {
    let { appActions } = this.props;

    appActions
      .getNewsList()
      .then(newsItems => this.setState({ newsItems }))
      .then(() => appActions.getBlogList())
      .then(blogItems => this.setState({ blogItems }))
      .then(() => this.setState({ showSpinner: false }))
      .catch(err => {
        console.warn(err);
        this.setState({ showSpinner: false });
      });
  };

  _fetchLatestRecords = records => {
    let [first, second, third, ...rest] = records.sort(
      (a, b) =>
        Date.parse(b.created).valueOf() - Date.parse(a.created).valueOf()
    );

    return [first, second, third];
  };
}

const Wrapper = styled.div`
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;

  @media screen and (max-width: 414px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const Content = styled.div`
  margin-right: ${props => (props.hasMarginRight ? 10 : 0)}px;
  max-width: 560px;
  width: 100%;

  & > .button-container {
    display: flex;
    justify-content: flex-end;
    padding: 0px 10px;

    & > button {
      border: 0px;
      background-color: white;
      text-decoration: underline;
      font-size: 16px;
      color: #71c6d0;
      letter-spacing: 1.6px;
      cursor: pointer;

      :focus {
        outline: none;
      }

      @media screen and (max-width: 600px) {
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 414px) {
    margin-right: 0px;
  }
`;

export default connect(
  null,
  ActionCreator
)(NewsBlogSection);
